import { LocalBadge, LocalBadgeStats } from '@app/interfaces/insights'
import Base from './Base'

class LocalBadges extends Base {
  static async list(locationId: number): Promise<LocalBadge[]> {
    const { data } = await this.get(`/locations/${locationId}/local_badges`)

    return data
  }

  static async stats(badgeId: number): Promise<LocalBadgeStats> {
    const { data } = await this.get(`/local_badges/${badgeId}/stats`, {
      // We don't want to use the default transformResponse in the Base class
      // as it will camelize the keys, which causes issues with some of the keys
      // in the response, such as the age demographic keys.
      // e.g. `{ 19-21: 20 }`, which changes to { 1921: 20 }.
      transformResponse: (data: string) => JSON.parse(data),
    })

    return data
  }
}

export default LocalBadges
